const select = document.getElementById("beta_signup_shop_name");

if (select) {
  const otherShopField = document.getElementById("other-shop-field");
  const update = () => {
    if (select.value === "Other") {
      otherShopField.classList.remove("d-none");
    } else {
      otherShopField.classList.add("d-none");
    }
  };

  select.addEventListener("change", update);
  window.addEventListener("pageshow", update); // on page-back
}
